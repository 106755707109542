import { useFm } from '@better-bit-fe/base-hooks';
import React from 'react';
import cls from 'classnames';
import styles from './index.module.less';

const Card = ({ num }) => {
  const t = useFm();
  return (
    <section className={styles.Card}>
      <div className={cls(styles.cardImg, styles[`img-${num}`])}></div>
      <div>
        <div className={styles.cardTitle}>{t(`homeAwardTitle${num}`)}</div>
        <div className={styles.cardDesc}>{t(`homeAwardDesc${num}`)}</div>
      </div>
    </section>
  );
};

export default Card;
