// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { useFm } from '@better-bit-fe/base-hooks';
import { getLang } from '@better-bit-fe/base-utils';
import { ReactComponent as CloseSvg } from '../../icon/h5close.svg';
import { ReactComponent as LogoSvg } from '../../icon/h5logo.svg';
import styles from './index.module.less';

function DownLoadLink() {
  const t = useFm();
  const lang = getLang();
  const [show, setShow] = useState(true);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const openDownLoadPage = useCallback(() => {
    window.location.href = `/${lang}/downloadApp`;
  });

  if (!show) {
    return;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <LogoSvg />
          <div className={styles.title}> SAFEX APP</div>
        </div>
        <div className={styles.right}>
          <div className={styles.download} onClick={openDownLoadPage}>
            {t('download')}
          </div>
          <CloseSvg className={styles.close} onClick={handleClose} />
        </div>
      </div>
    </div>
  );
}

export default DownLoadLink;
