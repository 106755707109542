//@ts-ignore
import { useFm } from '@better-bit-fe/base-hooks';
import React from 'react';
import cls from 'classnames';
import Card from './card';
import styles from './index.module.less';

const Security = () => {
  const t = useFm();
  const list = Array.from({ length: 6 });

  return (
    <section className={cls(styles.security, styles.flexcenter)}>
      <div className={styles.coreContent}>
        <div className={styles.blockTitleWrapper}>
          <div className={styles.blockTitle}>{t('homeSafe')}</div>
        </div>
        <div className={styles.content}>
          {list.map((it, i) => (
            <Card key={i} num={i + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Security;
