import { useFm } from '@better-bit-fe/base-hooks';
import React, { useMemo } from 'react';
import cls from 'classnames';
import TendChart from './tendChart';
import { getSymbolUrl } from '~/utils';
import styles from '../index.module.less';

const RowData = ({
  symbol, //应该是别名
  formattedLastPrice,
  changeRate24H,
  symbolAlias,
  line = []
}) => {
  const t = useFm();

  const useShowSymbol = useMemo(() => {
    return symbolAlias || symbol;
  }, [symbolAlias, symbol]);
  const gotoTradePage = () => {
    location.href = `${location.origin}/trade/usdt/${useShowSymbol}`;
  };

  return (
    <section className={styles.hotRow}>
      <div className={styles.itemRow}>
        <span>
          {symbolAlias && (
            <img className={styles.itemIcon} src={getSymbolUrl(symbolAlias)} />
          )}
          <span className={cls(styles.pairs)}>{symbolAlias}</span>
        </span>
        <span
          className={cls(styles.itemsCenter, {
            [styles.greenColor]: changeRate24H >= 0,
            [styles.redColor]: changeRate24H < 0
          })}
        >
          <span
            className={cls(styles.icon, {
              [styles.greenIcon]: changeRate24H >= 0,
              [styles.redIcon]: changeRate24H < 0
            })}
          />
          {changeRate24H >= 0 ? `+${changeRate24H}` : changeRate24H}%
        </span>
      </div>
      <div className={styles.itemRow}>
        <div>
          <div className={cls(styles.label)}>{t('lastPrice')}</div>
          <div className={cls(styles.lastPrice)}>{formattedLastPrice}</div>
        </div>

        <div>
          <div className={cls(styles.label)}>{t('chart')}</div>
          <TendChart chartDataList={line} isUp={!(changeRate24H < 0)} />
        </div>
        <div
          className={cls(styles.center, styles.tradeBtn)}
          onClick={gotoTradePage}
        >
          {t('trade')}
        </div>
      </div>
    </section>
  );
};

export default RowData;
