//@ts-ignore
import { useFm } from '@better-bit-fe/base-hooks';
import { useRouter } from 'next/router';
import { IRefer } from '~/types';
import udesk from '~/utils/udesk';
import { getLang } from '@better-bit-fe/base-utils';
import { Button } from 'antd';
import cls from 'classnames';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { getUrlAfterLangChange } from '~/utils';
import { linksWithLang } from '~/utils/linkMap';
import styles from './index.module.less';

const Footer = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();
  const router = useRouter();
  const { userInfo } = useUserInfo();
  const lang = getLang();

  // 如果已经登录，跳转到交易页
  const gotoPage = () => {
    if (userInfo) {
      location.href = `${location.origin}/trade/usdt/BTCUSDT`;
    } else {
      location.href = `${location.origin}/${lang}/login/register`;
    }
  };

  const gotoDownLoadPage = () => {
    const url = getUrlAfterLangChange('/downloadApp');
    window.open(url);
  };

  const handleOpenChat = () => {
    udesk.start(userInfo);
  };

  const currentYear = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    return String(year);
  }, []);

  return (
    <div className={cls(styles.footer, styles.flexcenter)}>
      <div className={cls(styles.footerCoreContent, styles.paddingBottom0)}>
        <div className={styles.footerTop}>
          <div className={styles.footerLeft}>
            <div className={styles.contentWrapper}>
              <div className={styles.title}>
                {userInfo ? t('tradeToearnProfit') : t('earnProfit')}
              </div>
              <Button className={styles.btn} onClick={gotoPage}>
                {userInfo ? t('rightNowTrade') : t('registerNow')}
              </Button>
            </div>
            <div className={styles.bgWrapper}>
              <div className={styles.bg} />
            </div>
          </div>
          <div className={styles.footerRight}>
            {/* 菜单1 */}
            <div>
              <div className={styles.menuTitle}>{t('aboutUS')}</div>
              <div className={styles.menuItemWrapper}>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['aboutUS']?.[lang]}
                    className={styles.footerLink}
                  >
                    {t('aboutUS')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['legal']?.[lang]}
                  >
                    {t('legal')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['userProtocol']?.[lang]}
                  >
                    {t('userProtocol')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['serviceProtocol']?.[lang]}
                  >
                    {t('serviceProtocol')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['privacyPolicy']?.[lang]}
                  >
                    {t('privacyPolicy')}
                  </a>
                </div>
              </div>
            </div>
            {/* 菜单2 */}
            <div>
              <div className={styles.menuTitle}>{t('product')}</div>
              <div className={styles.menuItemWrapper}>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['perpetualDerivatives']?.[lang]}
                    className={styles.footerLink}
                  >
                    {t('perpetualDerivatives')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['feeRate']?.[lang]}
                    className={styles.footerLink}
                  >
                    {t('feeRate')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['tradingRules']?.[lang]}
                  >
                    {t('tradingRules')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={linksWithLang?.['commonQuestion']?.[lang]}
                  >
                    {t('commonQuestion')}
                  </a>
                </div>

                <div className={styles.menuItems}>
                  <div onClick={gotoDownLoadPage} className={styles.footerLink}>
                    {t('downLoadCenter')}
                  </div>
                </div>
              </div>
            </div>
            {/* 菜单3 */}
            <div>
              <div className={styles.menuTitle}>{t('support')}</div>
              <div className={styles.menuItemWrapper}>
                <div className={styles.menuItems}>
                  <a
                    href={linksWithLang?.['helpCenter']?.[lang]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('helpCenter')}
                  </a>
                </div>

                <div className={styles.menuItems}>
                  <div onClick={handleOpenChat} className={styles.footerLink}>
                    {t('chatSupport')}
                  </div>
                </div>
                <div className={styles.menuItems}>
                  <a href="mailto:support@safex.hk">{t('contactUs')}</a>
                </div>
              </div>
            </div>
            {/* 菜单4 */}
            <div>
              <div className={styles.menuTitle}>{t('socials')}</div>
              <div className={styles.menuItemWrapper}>
                <div className={styles.menuItems}>
                  <a
                    href="https://twitter.com/safex_hk"
                    className={styles.socialwrapper}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={cls(styles.icon, styles.twitterIcon)} />
                    {t('twitter')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    href="https://t.me/SAFEXHK"
                    className={styles.socialwrapper}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={cls(styles.icon, styles.telegramIcon)} />
                    {t('telegram')}
                  </a>
                </div>
                <div className={styles.menuItems}>
                  <a
                    href="https://medium.com/@SAFEX_HK"
                    className={styles.socialwrapper}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={cls(styles.icon, styles.mediumIcon)} />
                    {t('medium')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>{t('copyrightText').replace('{{year}}', currentYear)}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
