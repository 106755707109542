// @ts-nocheck
import { useFm } from '@better-bit-fe/base-hooks';
import { IRefer } from '~/types';
import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Button } from 'antd';
import { PropsWithChildren, useMemo } from 'react';
import udesk from '~/utils/udesk';
import { getLang } from '@better-bit-fe/base-utils';
import cls from 'classnames';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { getMenu1, getMenu2, getMenu3 } from './menuPanel';
import styles from './index.module.less';
const { Panel } = Collapse;

const Footer = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  const [firstIsActive, setFirstIsActive] = useState();
  const [secondIsActive, setSecondIsActive] = useState();
  const [thirdIsActive, setThirdIsActive] = useState();
  const { userInfo } = useUserInfo();

  // 如果已经登录，跳转到交易页
  const gotoPage = () => {
    if (userInfo) {
      location.href = `${location.origin}/trade/usdt/BTCUSDT`;
    } else {
      const lang = getLang();
      location.href = `${location.origin}/${lang}/login/register`;
    }
  };

  const handleOpenChat = () => {
    udesk.start(userInfo);
  };

  const items = useMemo(() => {
    return [
      {
        key: 1,
        label: (
          <div className={styles.menuTitle}>
            <div>{t('aboutUS')}</div>
            <div
              className={cls(styles.collapseIcon, {
                [styles.active]: firstIsActive,
                [styles.noActive]: !firstIsActive
              })}
            />
          </div>
        ),
        children: getMenu1(t)
      },
      {
        key: 2,
        label: (
          <div className={styles.menuTitle}>
            <div>{t('product')}</div>
            <div
              className={cls(styles.collapseIcon, {
                [styles.active]: secondIsActive,
                [styles.noActive]: !secondIsActive
              })}
            />
          </div>
        ),
        children: getMenu2(t)
      },
      {
        key: 3,
        label: (
          <div className={styles.menuTitle}>
            <div>{t('support')}</div>
            <div
              className={cls(styles.collapseIcon, {
                [styles.active]: thirdIsActive,
                [styles.noActive]: !thirdIsActive
              })}
            />
          </div>
        ),
        children: getMenu3(t, handleOpenChat)
      }
    ];
  }, [t, secondIsActive, secondIsActive, thirdIsActive]);

  const onCollapseChange = (keys) => {
    const first = keys.includes('1');
    const second = keys.includes('2');
    const third = keys.includes('3');
    setFirstIsActive(first);
    setSecondIsActive(second);
    setThirdIsActive(third);
  };

  const currentYear = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    return String(year);
  }, []);

  return (
    <div className={cls(styles.footer, styles.flexcenter)}>
      <div className={cls(styles.coreContent, styles.paddingBottom0)}>
        <div className={styles.footerTop}>
          <div className={styles.footerBanner}>
            <div className={styles.content}>
              <div className={styles.title}>{t('earnProfit')}</div>
              <Button className={styles.btn} onClick={gotoPage}>
                {t('registerNow')}
              </Button>
            </div>
          </div>
          <div className={styles.footerBrand}>
            <div className={styles.footerLogo} />
            <div>SAFEX</div>
          </div>
          <div className={styles.footerMenu}>
            <Collapse onChange={onCollapseChange}>
              {items.map((it) => {
                return (
                  <Panel header={it.label} key={it.key}>
                    {it.children}
                  </Panel>
                );
              })}
            </Collapse>
            <div className={cls(styles.flex, styles.menuItemWrapper)}>
              <div className={styles.menuItems}>
                <a
                  href="https://twitter.com/safex_hk"
                  className={styles.socialwrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={cls(styles.icon, styles.twitterIcon)} />
                </a>
              </div>
              <div className={styles.menuItems}>
                <a
                  href="https://t.me/SAFEXHK"
                  className={styles.socialwrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={cls(styles.icon, styles.telegramIcon)} />
                </a>
              </div>
              <div className={styles.menuItems}>
                <a
                  href="https://medium.com/@SAFEX_HK"
                  className={styles.socialwrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={cls(styles.icon, styles.mediumIcon)} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>{t('copyrightText').replace('{{year}}', currentYear)}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
