//@ts-nocheck
import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect } from 'react';
import cls from 'classnames';
import { getLang } from '@better-bit-fe/base-utils';
import { useUserInfo } from '@better-bit-fe/base-provider';
import Card from './card';
import styles from './index.module.less';

const Award = () => {
  const t = useFm();
  const { userInfo } = useUserInfo();
  const list = Array.from({ length: 3 });

  // // 如果已经登录，跳转到交易页
  // const gotoPage = () => {
  //   const lang = getLang();
  //   if (userInfo) {
  //     location.href = `${location.origin}/trade/usdt/BTCUSDT`;
  //   } else {
  //     location.href = `${location.origin}/${lang}/login/register`;
  //   }
  // };

  return (
    <section className={cls(styles.award, styles.flexcenter)}>
      <div className={styles.coreContent}>
        <div className={styles.blockTitleWrapper}>
          <div className={styles.blockTitle}>{t('homeAward')}</div>
          {/* <div className={styles.blockExtra} onClick={gotoPage}>
            {t('viewMore')}
            <span className={styles.blockExtraIcon} /> 
          </div>*/}
        </div>
        <div className={styles.content}>
          {list.map((it, i) => (
            <Card key={i} num={i + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Award;
