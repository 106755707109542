//@ts-ignore
import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { getLang } from '@better-bit-fe/base-utils';
import DownloadIcons from '~/components/downloadIcons';
import AppStatus from '~/components/appStatus';
import { useUserInfo } from '@better-bit-fe/base-provider';
import styles from './index.module.less';

const HomeBanner = () => {
  const t = useFm();
  const { userInfo } = useUserInfo();

  // 如果已经登录，跳转到交易页
  const gotoPage = () => {
    if (userInfo) {
      location.href = `${location.origin}/trade/usdt/BTCUSDT`;
    } else {
      const lang = getLang();
      location.href = `${location.origin}/${lang}/login/register`;
    }
  };
  return (
    <section className={styles.homeBanner}>
      <div className={styles.textWrapper}>
        <div className={styles.brand}> #SAFEX.HK</div>
        <div className={styles.text}>{t('homeBannerTitle0')}</div>
        <div className={styles.text}>
          {t('homeBannerTitle1')}
          {t('homeBannerTitle2')}
        </div>
        <div className={styles.btn} onClick={gotoPage}>
          <div className={styles.btnWrapper}>
            {userInfo ? t('rightNowTrade') : t('homeBannerBtn')}
            <span className={styles.linkIcon} />
          </div>
        </div>
        <DownloadIcons theme="brand" />
        <AppStatus />
      </div>
    </section>
  );
};

export default HomeBanner;
