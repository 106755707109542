// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Footer, HomeBanner, Award, Security, HomeData } from './index';
import DownLoadLink from '~/components/downloadLink';

function H5HomePage() {
  return (
    <div>
      <HomeBanner />
      <HomeData />
      <Security />
      <Award />
      <Footer />
      <DownLoadLink />
    </div>
  );
}

export default H5HomePage;
