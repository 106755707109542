// @ts-nocheck
export function formatThousandDigit(val: string) {
  if (val?.includes('.')) {
    const splitValue = val.split('.');
    const formatInt = Number(splitValue[0]).toLocaleString();
    const decimals = splitValue[1];
    return formatInt + '.' + decimals;
  } else {
    return Number(val).toLocaleString();
  }
}

// 用的是别名
export const getSymbolUrl = (name) => {
  const coin = name.replace('USDT', '');
  const publicUrl = `${location.origin}/static/image`;
  const isPng = ['eth', 'btc'].includes(coin?.toLowerCase());
  const prefix = `${publicUrl}/dark/${coin?.toLowerCase()}`;
  return isPng ? `${prefix}.png` : `${prefix}.svg`;
};

export const toNumberZero = (number) =>
  Number.isNaN(Number(number)) ? 0 : Number(number);

export function emailCheck(val: string) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(val);
}

export function getLang() {
  if (typeof window !== 'undefined') {
    const langReg = /([a-z]{2}-[A-Z]{2})/;
    let refLang = location.href.match(langReg);
    if (refLang) {
      refLang = refLang[0];
    }
    const lang = refLang || localStorage.getItem('LANG_KEY') || 'zh-CN';
    //获取到之后set
    localStorage.setItem('LANG_KEY', lang);
    return lang;
  }
  return 'en-US';
}

export const getUrlAfterLangChange = (path) => {
  if (typeof window !== 'undefined') {
    const langReg = /([a-z]{2}-[A-Z]{2,3})/;
    const lang = getLang();
    // 导航上有语言，直接拼接
    //如果是en-US/aboutUs点击呢
    // if (langReg.test(location.href)) {
    //   lang = location.href.match(langReg)[0];
    // }

    const newUrl = `/${lang}${path}/`;
    return newUrl;
  }
  console.log('newUrl no broswer', path);
  return path;
};

export const throttle = (fun: () => void, delay: number) => {
  let [last, deferTimer]: [number, NodeJS.Timer | undefined] = [0, undefined];
  return function () {
    const now: number = +new Date();
    if (last && now < last + delay) {
      deferTimer && clearTimeout(deferTimer as NodeJS.Timer);
      deferTimer = setTimeout(function () {
        last = now;
        fun();
      }, delay);
    } else {
      last = now;
      fun();
    }
  };
};
