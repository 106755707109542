export const linksWithLang = {
  aboutUS: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/guan-yu-wo-men',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/about-us',
    'en-US': 'https://help.safex.hk/safex-help/other-help/about-us',
    'vi-VN': 'https://help.safex.hk/safex-tro-giup/tro-giup-khac/ve-chung-toi'
  },

  legal: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/falsheng-ming',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/legal-notices',
    'en-US': 'https://help.safex.hk/safex-help/other-help/legal-notices',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/tro-giup-khac/tuyen-bo-phap-ly'
  },
  userProtocol: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/yong-hu-xie-yi',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/user-agreement',
    'en-US': 'https://help.safex.hk/safex-help/other-help/user-agreement',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/tro-giup-khac/thoa-thuan-nguoi-dung'
  },
  serviceProtocol: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/fu-wu-tiao-kuan',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/terms-of-service',
    'en-US': 'https://help.safex.hk/safex-help/other-help/terms-of-service',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/tro-giup-khac/dieu-khoan-dich-vu'
  },
  privacyPolicy: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/yin-si-sheng-ming',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/privacy-statement',
    'en-US': 'https://help.safex.hk/safex-help/other-help/privacy-statement',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/tro-giup-khac/ban-tuyen-bo-quyen-rieng-tu'
  },
  perpetualDerivatives: {
    'zh-CN': 'https://help.safex.hk/help/he-yue-jiao-yi/he-yue-jie-shao',
    'zh-TW':
      'https://help.safex.hk/safex-help/derivatives-trading/derivatives-introduction',
    'en-US':
      'https://help.safex.hk/safex-help/derivatives-trading/derivatives-introduction',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/giao-dich-hop-dong/gioi-thieu-hop-dong'
  },
  feeRate: {
    'zh-CN':
      'https://help.safex.hk/help/he-yue-jiao-yi/he-yue-jiao-yi-shou-xu-fei-shuo-ming',
    'zh-TW':
      'https://help.safex.hk/safex-help/derivatives-trading/transaction-fee',
    'en-US':
      'https://help.safex.hk/safex-help/derivatives-trading/transaction-fee',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/giao-dich-hop-dong/giai-thich-phi-giao-dich-hop-dong'
  },
  tradingRules: {
    'zh-CN': 'https://help.safex.hk/help/he-yue-jiao-yi/he-yue-jiao-yi-can-shu',
    'zh-TW':
      'https://help.safex.hk/safex-help/derivatives-trading/derivatives-trading-parameters',
    'en-US':
      'https://help.safex.hk/safex-help/derivatives-trading/derivatives-trading-parameters',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/giao-dich-hop-dong/tham-so-giao-dich-hop-dong'
  },
  commonQuestion: {
    'zh-CN': 'https://help.safex.hk/help/qi-ta-bang-zhu/chang-jian-wen-ti',
    'zh-TW': 'https://help.safex.hk/safex-help/other-help/frequently-faq',
    'en-US': 'https://help.safex.hk/safex-help/other-help/frequently-faq',
    'vi-VN':
      'https://help.safex.hk/safex-tro-giup/tro-giup-khac/cau-hoi-thuong-gap'
  },
  downLoadCenter: {
    'zh-CN': 'https://www.safex.hk/zh-CN/downloadApp/',
    'zh-TW': 'https://www.safex.hk/zh-TW/downloadApp/',
    'en-US': 'https://www.safex.hk/en-US/downloadApp/',
    'vi-VN': 'https://www.safex.hk/vi-VN/downloadApp/'
  },
  helpCenter: {
    'zh-CN': 'https://help.safex.hk/help/',
    'zh-TW': 'https://help.safex.hk/safex-help',
    'en-US': 'https://help.safex.hk/safex-help',
    'vi-VN': 'https://help.safex.hk/safex-tro-giup'
  }
};
