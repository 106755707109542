// @ts-nocheck
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { initWs } from '~/publicWS/initMarketWS';
import useSymbolConfigList from '~/hooks/useSymbolConfigList';

interface IContext {
  symbolConfig: any[];
}
// 创造context
const SymbolConfigContext = createContext<IContext>({
  symbolConfig: []
});

// 创建provider,使用provider就是不希望每次user的时候都初始化
const SymbolConfigProvider = ({ children }) => {
  const { symbolConfig, getSymbolConfigList } = useSymbolConfigList();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getSymbolConfigList();
    initWs();
  };

  return (
    <SymbolConfigContext.Provider
      value={{
        symbolConfig
      }}
    >
      {children}
    </SymbolConfigContext.Provider>
  );
};

// 创建hook
const useSymbolConfig = () => {
  return useContext(SymbolConfigContext);
};

export { SymbolConfigContext, SymbolConfigProvider, useSymbolConfig };
