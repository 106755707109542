import React from 'react';
import RightRow from './row';
import styles from '../index.module.less';

const RankTab = ({ data }) => {
  return (
    <div className={styles.marketContent}>
      {data.length ? data.map((it, i) => <RightRow key={i} {...it} />) : null}
    </div>
  );
};

export default RankTab;
