//@ts-nocheck
import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect } from 'react';
import cls from 'classnames';
import { getLang } from '@better-bit-fe/base-utils';
import { useUserInfo } from '@better-bit-fe/base-provider';
import Card from './card';
import styles from './index.module.less';

const Award = () => {
  const t = useFm();
  const { userInfo } = useUserInfo();
  const list = Array.from({ length: 3 });

  return (
    <section className={cls(styles.award, styles.flexcenter)}>
      <div className={styles.coreContent}>
        <div className={styles.blockTitleWrapper}>
          <div className={styles.blockTitle}>{t('homeAward')}</div>
        </div>
        <div className={styles.content}>
          {list.map((it, i) => (
            <Card key={i} num={i + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Award;
