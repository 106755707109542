// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { useFm } from '@better-bit-fe/base-hooks';
import cls from 'classnames';
import { Input, message } from 'antd';
import { getLang, isMobile } from '@better-bit-fe/base-utils';
import udesk from '~/utils/udesk';
import styles from './index.module.less';
function Chat() {
  const t = useFm();
  const { userInfo, isLogin } = useUserInfo();
  const [showModal, setShowModal] = useState(false);
  const [isVisitor, setIsVisitor] = useState(false);
  const [email, setEmaill] = useState();

  // 点击客服logo
  // 当已经初始化的时候，会直接唤起面板
  const handleOpenChat = () => {
    console.log('点击客服icon');
    if (isLogin) {
      udesk.start(userInfo);
    } else {
      // 游客模式，关闭客服，再打开应该还在一个会话周期内，所以直接打开即可
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // 回到登录或者游客访问页面
  const handleBack = () => {
    setEmaill();
    setIsVisitor(false);
  };

  const gotoLogin = () => {
    const lang = getLang();
    window.location.href = `/${lang}/login`;
  };

  const handleVisitor = () => {
    setIsVisitor(true);
  };

  const handleStartChat = () => {
    // 验证邮箱
    if (!email) {
      message.error(t('emailIsEmptyTips'));
      return;
    }
    if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)) {
      message.error(t('emailInvalidTips'));
      return;
    }

    const data = {
      id: email, //，同email还是同一个用户
      email
    };
    udesk.start(data);
    // 回到初始状态
    setIsVisitor(false);
    setEmaill();
    setShowModal(false);
  };

  const handleChangeEmail = (e) => {
    const val = e.target.value;
    setEmaill(val);
  };

  return (
    <div className={styles.chat}>
      {!showModal && (
        <div id={isLogin ? 'safexChat' : ''}>
          <div className={styles.chatWrapper} onClick={handleOpenChat} />
        </div>
      )}

      {/* 登录或者游客方式 */}
      {showModal && (
        <div className={styles.guideModal}>
          {!isVisitor ? (
            <>
              <div className={cls(styles.guideTitle, styles.flexBetween)}>
                {t('chatLoginGuide')}
                <span className={styles.closeIcon} onClick={handleCloseModal} />
              </div>
              <div className={styles.guideOption}>
                <div
                  className={cls(styles.optionBtn, styles.optionLogin)}
                  onClick={gotoLogin}
                >
                  {t('login')}
                </div>
                <div
                  className={cls(styles.optionBtn, styles.optionVisitor)}
                  onClick={handleVisitor}
                >
                  {t('continueAsVisitor')}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.guideTitle}>
                <span className={styles.backIcon} onClick={handleBack} />
                {t('enterEmail')}
              </div>
              <div className={styles.guideOption}>
                <Input
                  value={email}
                  onChange={handleChangeEmail}
                  placeholder={t('plsEnterEmail')}
                />
                <div
                  className={cls(styles.optionBtn, styles.optionStartChat)}
                  onClick={handleStartChat}
                  // id="safexStartChat"
                  // id="safexChat"
                >
                  {t('startChat')}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Chat;
