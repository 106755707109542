// @ts-nocheck
import { Env } from '@region-lib/env';
import { urlInfo } from '@region-lib/env';
import allSymbolQuoteStream from './allSymbolObservables';
import EnhancedWS from './enhancedWS';

const { WS_HOST } = Env;

// 订阅共有行情
export const initWs = async (cb) => {
  const publicWSUrl = `${WS_HOST}/realtime_public?v=9`;
  const publicWS = new EnhancedWS(publicWSUrl, {
    autoConnect: true,
    pingInterval: 10000,
    debug: urlInfo.env === 'test'
  });
  publicWS.on('connect', () => {
    publicWS.private('tickers.all', ({ data }) => {
      // cb(data);
      allSymbolQuoteStream.next(data); //数据流的形式去处理
    });
  });
};
