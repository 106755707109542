import { useFm } from '@better-bit-fe/base-hooks';
import React from 'react';
import styles from './index.module.less';
import cls from 'classnames';
import { getSymbolUrl } from '~/utils';

const RowData = ({
  symbol,
  symbolAlias,
  formattedLastPrice,
  changeRate24H
}) => {
  const t = useFm();
  return (
    <section className={styles.rightRowItem}>
      <span className={cls(styles.left, styles.pairs)}>
        {symbolAlias && (
          <img className={styles.itemIcon} src={getSymbolUrl(symbolAlias)} />
        )}
        <span> {symbolAlias} </span>
      </span>
      <span className={cls(styles.center, styles.price)}>
        {formattedLastPrice}
      </span>

      <span
        className={cls(styles.center, styles.change, {
          [styles.greenColor]: changeRate24H >= 0,
          [styles.redColor]: changeRate24H < 0
        })}
      >
        {changeRate24H >= 0 ? `+${changeRate24H}` : changeRate24H}%
      </span>
    </section>
  );
};

export default RowData;
