// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Footer, HomeBanner, Award, Security, HomeData } from './index';

function PCHomePage() {
  return (
    <div>
      <HomeBanner />
      <HomeData />
      <Security />
      <Award />
      <Footer />
    </div>
  );
}

export default PCHomePage;
