// @ts-nocheck
import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, useCallback } from 'react';
import cls from 'classnames';
import { Tabs } from 'antd';
import HotTab from './hotTab';
import RankTab from './rankTab';
import useAllSymbolQuote from '~/publicWS/useAllSymbolQuote';
import { getTrendKline } from '~/api';
import { useSymbolConfig } from '~/context/symbolConfig';
import styles from './index.module.less';

const HomeData = () => {
  const t = useFm();
  const [upList, setUpList] = useState([]); //涨幅榜
  const [downList, setDownList] = useState([]); //跌幅榜
  const [hotData, setHotData] = useState([]); //热门榜
  const [hotSymbolNames, setHotSymbolNames] = useState([]); //热门币种名称
  const allSymbolQuoteData = useAllSymbolQuote(); //所有行情数据
  const { symbolConfig } = useSymbolConfig();

  useEffect(() => {
    getHotSymbol();
  }, [symbolConfig]);

  // 获取热门币种名称
  const getHotSymbol = () => {
    const orderSymbol = symbolConfig && symbolConfig.sort(orderFun).slice(0, 6); //已经排序的数据
    const hotNames = orderSymbol.map((it) => it.symbolName);
    setHotSymbolNames(hotNames);
  };

  // 获取展示的数据
  const getShowSymbol = useCallback(() => {
    const res = [];
    hotSymbolNames.forEach(async (name) => {
      if (allSymbolQuoteData[name]) {
        res.push(allSymbolQuoteData[name]);
      } // 防止没有数据
    });
    setHotData(res);
    const allList = Object.values(allSymbolQuoteData); // 按照24小时涨跌幅排序
    const sortBy24Change = allList.sort(upFun);
    const list = sortBy24Change.filter((item) => {
      return hotSymbolNames.includes(item?.symbol);
    });
    // 涨幅榜，当数据都为0 的时候
    const up = list.slice(-3).reverse();
    const down = list.slice(0, 3);
    setUpList(up);
    setDownList(down);
  }, [allSymbolQuoteData]);

  useEffect(() => {
    getShowSymbol();
  }, [getShowSymbol, hotSymbolNames]);

  const orderFun = (a, b) => {
    return a.symbol - b.symbol;
  };

  const upFun = (a, b) => {
    // 如果a-b等于0，那就按照字母排序
    const v = a.changeRate24H - b.changeRate24H;
    if (v === 0) {
      const v0 = a.symbol.charCodeAt(0) - b.symbol.charCodeAt(0);
      const v1 = a.symbol.charCodeAt(1) - b.symbol.charCodeAt(1);
      const v2 = a.symbol.charCodeAt(2) - b.symbol.charCodeAt(2);
      return v0 || v1 || v2;
    } else {
      return v;
    }
  }; //从小到大

  const gotoTradePage = () => {
    location.href = `${location.origin}/trade/usdt/BTCUSDT`;
  };

  const items = [
    {
      key: '1',
      label: t('hotCoins'),
      children: <HotTab hotData={hotData} />
    },
    {
      key: '2',
      label: t('upList'),
      children: <RankTab data={upList} />
    },
    {
      key: '3',
      label: t('downList'),
      children: <RankTab data={downList} />
    }
  ];

  const operations = (
    <div className={styles.blockExtra} onClick={gotoTradePage}>
      {t('viewMore')}
      <span className={styles.blockExtraIcon} />
    </div>
  );

  return (
    <section className={cls(styles.homeData, styles.flexcenter)}>
      <div className={styles.coreContent}>
        <div className={styles.blockTitle}>{t('catchOpportunity')}</div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          tabBarExtraContent={operations}
        />
      </div>
    </section>
  );
};

export default HomeData;
