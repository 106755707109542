import { useFm } from '@better-bit-fe/base-hooks';
import React, { useMemo } from 'react';
import styles from './index.module.less';
import cls from 'classnames';
import TendChart from './tendChart';
import { getSymbolUrl } from '~/utils';

const RowData = ({
  symbol, //应该是别名
  formattedLastPrice,
  changeRate24H,
  symbolAlias,
  line = []
}) => {
  const t = useFm();

  const useShowSymbol = useMemo(() => {
    return symbolAlias || symbol;
  }, [symbolAlias, symbol]);
  const gotoTradePage = () => {
    location.href = `${location.origin}/trade/usdt/${useShowSymbol}`;
  };

  return (
    <section className={styles.leftRowItem}>
      <span className={cls(styles.pairs, styles.left)}>
        {symbolAlias && (
          <img className={styles.itemIcon} src={getSymbolUrl(symbolAlias)} />
        )}
        <span>{symbolAlias}</span>
      </span>
      <span className={cls(styles.center, styles.price)}>
        {formattedLastPrice}
      </span>
      <span
        className={cls(styles.center, styles.change, {
          [styles.greenColor]: changeRate24H >= 0,
          [styles.redColor]: changeRate24H < 0
        })}
      >
        {changeRate24H >= 0 ? `+${changeRate24H}` : changeRate24H}%
      </span>
      <span className={cls(styles.center, styles.chart)}>
        <TendChart chartDataList={line} isUp={!(changeRate24H < 0)} />
      </span>
      <span
        className={cls(styles.center, styles.trade, styles.tradeBtn)}
        onClick={gotoTradePage}
      >
        {t('trade')}
      </span>
    </section>
  );
};

export default RowData;
