// @ts-nocheck
import sha1 from 'js-sha1';
import { getLang } from '@better-bit-fe/base-utils';

const langmap = {
  'en-US': 'en-us',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-TW'
};
//

const getSignature = (nonce, timestamp, webToken) => {
  const key = '288958d00bdda5b3519df94dd362e967';
  let signStr = `nonce=${nonce}&timestamp=${timestamp}&web_token=${webToken}&${key}`;
  signStr = sha1(signStr);
  signStr = signStr.toUpperCase();
  return signStr;
};

const makeRandomStr = (num) => {
  const e = num;
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const a = t.length;
  let n = '';
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
};

const obj = {
  show: false, //面板的状态
  start(data) {
    console.log('init', !window.ud, data);
    if (!window.ud) {
      this.createUdeskScript(data); //用户登录时需要获取用户信息
      setTimeout(() => {
        ud('showPanel');
      }, 100);
    } else {
      !this.show && ud('showPanel');
    }
  },
  openUdPanel() {
    if (window.ud && !this.show) {
      const ud = window.ud;
      ud('showPanel');
    }
  },
  hideUdPanel() {
    if (window.ud && this.show) {
      const ud = window.ud;
      ud('hidePanel');
    }
  },
  createUdeskScript(data) {
    (function (a, h, c, b, f, g) {
      a['UdeskApiObject'] = f;
      a[f] =
        a[f] ||
        function () {
          (a[f].d = a[f].d || []).push(arguments);
        };
      g = h.createElement(c);
      g.async = 1;
      g.src = b;
      c = h.getElementsByTagName(c)[0];
      c.parentNode.insertBefore(g, c);
    })(
      window,
      document,
      'script',
      'https://assets-cli.s2.udesk.cn/im_client/js/udeskApi.js',
      'ud'
    );
    const ud = window.ud;
    const Timestamp = new Date().getTime(); //13位
    const Nonce = makeRandomStr(32);
    const Webtoken = data?.id;
    const lang = langmap[getLang()];
    const udParams = {
      code: '1g9b9fj0', //公司唯一标识
      link: `https://1359545.s2.udesk.cn/im_client/?web_plugin_id=48839`, //公司IM Client链接地址
      customer: {
        c_name: data?.nick_name,
        c_email: data?.email,
        c_phone: data?.mobile,
        nonce: Nonce,
        timestamp: Timestamp,
        web_token: Webtoken, //客户唯一标识
        signature: getSignature(Nonce, Timestamp, Webtoken)
      }, //客户身份认证参数
      panel: {
        onToggle: function (data) {
          if (data.visible) {
            this.show = true;
          } else {
            this.show = false;
          }
        }
      },
      mode: 'inner', //按钮打开窗口显示模式
      color: '#307AE8',
      pos_flag: 'crb', //按钮形状+位置 vrb表示纵向右下角
      language: lang, //语言-英文
      onlineText: '',
      offlineText: '',
      targetSelector: '#safexChat',
      // selector: '#safexStartChat',
      onUnread: function (data) {
        const count = data.count;
      },
      onReady: function () {
        console.log('初始化完成onReady');
      }
    };
    ud(udParams);
  }
};

export default obj;
