//@ts-ignore
import { getUrlAfterLangChange } from '~/utils';
import { linksWithLang } from '~/utils/linkMap';
import { getLang } from '@better-bit-fe/base-utils';
import styles from './index.module.less';

const gotoDownLoadPage = () => {
  const url = getUrlAfterLangChange('/downloadApp');
  window.open(url);
};
const lang = getLang();

const getMenu1 = (t) => {
  return (
    <div className={styles.menuItemWrapper}>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['aboutUS']?.[lang]}
          className={styles.footerLink}
        >
          {t('aboutUS')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['legal']?.[lang]}
        >
          {t('legal')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['userProtocol']?.[lang]}
        >
          {t('userProtocol')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['serviceProtocol']?.[lang]}
        >
          {t('serviceProtocol')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['privacyPolicy']?.[lang]}
        >
          {t('privacyPolicy')}
        </a>
      </div>
    </div>
  );
};

const getMenu2 = (t) => {
  return (
    <div className={styles.menuItemWrapper}>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['perpetualDerivatives']?.[lang]}
          className={styles.footerLink}
        >
          {t('perpetualDerivatives')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['feeRate']?.[lang]}
          className={styles.footerLink}
        >
          {t('feeRate')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['tradingRules']?.[lang]}
        >
          {t('tradingRules')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <a
          target="_blank"
          rel="noreferrer"
          href={linksWithLang?.['commonQuestion']?.[lang]}
        >
          {t('commonQuestion')}
        </a>
      </div>

      <div className={styles.menuItems}>
        <div onClick={gotoDownLoadPage} className={styles.footerLink}>
          {t('downLoadCenter')}
        </div>
      </div>
    </div>
  );
};

const getMenu3 = (t, handleOpenChat) => {
  return (
    <div className={styles.menuItemWrapper}>
      <div className={styles.menuItems}>
        <a
          href={linksWithLang?.['helpCenter']?.[lang]}
          target="_blank"
          rel="noreferrer"
        >
          {t('helpCenter')}
        </a>
      </div>
      <div className={styles.menuItems}>
        <div onClick={handleOpenChat} className={styles.footerLink}>
          {t('chatSupport')}
        </div>
      </div>
      <div className={styles.menuItems}>
        <a href="mailto:support@safex.hk">{t('contactUs')}</a>
      </div>
    </div>
  );
};

export { getMenu1, getMenu2, getMenu3 };
