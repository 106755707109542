import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, useCallback } from 'react';
import { useSymbolConfig } from '~/context/symbolConfig';
import dayjs from 'dayjs';
import LeftRow from './row';
import { getTrendKline } from '~/api';
import styles from '../index.module.less';

const HotTab = ({ hotData }) => {
  const t = useFm();
  const { symbolConfig } = useSymbolConfig();
  const [allKlinesDatas, setAllKlinesData] = useState({}); //热门币种的k线数据-用于走势图
  useEffect(() => {
    getAllKineData();
  }, [symbolConfig, hotData.length]);

  // 趋势图的数据只需要初始化一次即可
  const getAllKineData = () => {
    const symbolNames = hotData.map((it) => it.symbol);
    const lineDatas = {};
    symbolNames.forEach(async (it) => {
      const data = await getKineDataBySymbol(it);
      lineDatas[it] = data;
    });
    setAllKlinesData(lineDatas);
  };

  const getKineDataBySymbol = async (symbolName) => {
    const to = dayjs().unix();
    const from = dayjs().subtract(1, 'day').unix(); //一天前
    const params = {
      symbol: symbolName,
      resolution: '60', //分钟
      from,
      to
    };
    const res = await getTrendKline(params);
    const closeList = res?.list.map((it) => it.close);
    // console.log('K线的数据', symbolName, res, closeList);
    return closeList;
  };

  return (
    <div className={styles.marketContent}>
      {hotData.length
        ? hotData.map((it, i) => (
            <LeftRow
              key={i}
              {...it}
              line={allKlinesDatas?.[it?.symbol] || []}
            />
          ))
        : null}
    </div>
  );
};

export default HotTab;
