import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, useCallback } from 'react';
import cls from 'classnames';
import dayjs from 'dayjs';
import LeftRow from './rowLeft';
import RightRow from './rowRight';
import useAllSymbolQuote from '~/publicWS/useAllSymbolQuote';
import { getTrendKline } from '~/api';
import { useSymbolConfig } from '~/context/symbolConfig';
import styles from './index.module.less';

const HomeData = () => {
  const t = useFm();
  const [upList, setUpList] = useState([]); //涨幅榜
  const [downList, setDownList] = useState([]); //跌幅榜
  const [hotData, setHotData] = useState([]); //热门榜

  const [hotSymbols, setHotSymbols] = useState([]); //热门币种名称
  const [allKlinesDatas, setAllKlinesData] = useState({}); //热门币种的k线数据-用于走势图
  const allSymbolQuoteData = useAllSymbolQuote(); //所有行情数据
  const { symbolConfig } = useSymbolConfig();

  useEffect(() => {
    getHotSymbol();
  }, [symbolConfig]);

  const getHotSymbol = () => {
    const orderSymbol = symbolConfig && symbolConfig.sort(orderFun).slice(0, 6); //已经排序的数据
    const symbolNames = orderSymbol.map((it) => it.symbolName);
    getAllKineData(symbolNames);
    setHotSymbols(symbolNames);
  };

  // 趋势图的数据只需要初始化一次即可
  const getAllKineData = (symbolNames) => {
    const lineDatas = {};
    symbolNames.forEach(async (it) => {
      const data = await getKineDataBySymbol(it);
      lineDatas[it] = data;
    });
    setAllKlinesData(lineDatas);
  };

  const getKineDataBySymbol = async (symbolName) => {
    const to = dayjs().unix();
    const from = dayjs().subtract(1, 'day').unix(); //一天前
    const params = {
      symbol: symbolName,
      resolution: '60', //分钟
      from,
      to
    };
    const res = await getTrendKline(params);
    const closeList = res?.list.map((it) => it.close);
    // console.log('K线的数据', symbolName, res, closeList);
    return closeList;
  };

  const getShowSymbol = () => {
    const res = [];
    hotSymbols.forEach(async (name) => {
      // 防止没有数据
      if (allSymbolQuoteData[name]) {
        res.push(allSymbolQuoteData[name]);
      }
    });
    setHotData(res);
    const allList = Object.values(allSymbolQuoteData); // 按照24小时涨跌幅排序
    const sortBy24Change: any = allList.sort(upFun);

    const list = sortBy24Change.filter((item) => {
      return hotSymbols.includes(item?.symbol);
    });
    const up = list.slice(-3).reverse();
    const down = list.slice(0, 3);
    setUpList(up);
    setDownList(down);
  };

  useEffect(() => {
    getShowSymbol();
  }, [allSymbolQuoteData, hotSymbols]);

  const orderFun = (a, b) => {
    return a.symbol - b.symbol;
  };

  const upFun = (a, b) => {
    // 如果a-b等于0，那就按照字母排序
    const v = Number(a.changeRate24H) - Number(b.changeRate24H);
    if (v === 0) {
      const v0 = a.symbol.charCodeAt(0) - b.symbol.charCodeAt(0);
      const v1 = a.symbol.charCodeAt(1) - b.symbol.charCodeAt(1);
      const v2 = a.symbol.charCodeAt(2) - b.symbol.charCodeAt(2);
      return v0 || v1 || v2;
    } else {
      return v; // 或者取changeRate24H
    }
  }; //从小到大

  const gotoTradePage = () => {
    location.href = `${location.origin}/trade/usdt/BTCUSDT`;
  };

  return (
    <section className={cls(styles.homeData, styles.flexcenter)}>
      <div className={styles.coreContent}>
        <div className={styles.blockTitleWrapper}>
          <div className={styles.blockTitle}>{t('catchOpportunity')}</div>
          <div className={styles.blockExtra} onClick={gotoTradePage}>
            {t('viewMore')}
            <span className={styles.blockExtraIcon} />
          </div>
        </div>
        <div className={styles.marketWrapper}>
          <div className={styles.marketLeft}>
            <div className={styles.box}>
              <div className={styles.tableTitle}>{t('hotCoins')}</div>
              <div className={styles.tableContent}>
                <div className={styles.header}>
                  <div className={cls(styles.pairs, styles.left)}>
                    {t('pairs')}
                  </div>
                  <div className={cls(styles.price, styles.center)}>
                    {t('lastPrice')}
                  </div>
                  <div className={cls(styles.change, styles.center)}>
                    {t('24change')}
                  </div>
                  <div className={cls(styles.chart, styles.center)}>
                    {t('chart')}
                  </div>
                  <div className={cls(styles.trade, styles.center)}>
                    {t('trade')}
                  </div>
                </div>
                <div className={styles.content}>
                  {hotData.length
                    ? hotData.map((it, i) => (
                        <LeftRow
                          key={i}
                          {...it}
                          line={allKlinesDatas?.[it?.symbol] || []}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.marketRight}>
            <div className={styles.box}>
              <div className={styles.tableTitle}>{t('upList')}</div>
              <div className={styles.tableContent}>
                <div className={styles.content}>
                  {upList.length
                    ? upList.map((it, i) => <RightRow key={i} {...it} />)
                    : null}
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.tableTitle}>{t('downList')}</div>
              <div className={styles.tableContent}>
                <div className={styles.content}>
                  {downList.length
                    ? downList.map((it, i) => <RightRow key={i} {...it} />)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeData;
