import { useFm } from '@better-bit-fe/base-hooks';
import React from 'react';
import cls from 'classnames';
import { getSymbolUrl } from '~/utils';
import styles from '../index.module.less';

const RowData = ({
  symbol,
  symbolAlias,
  formattedLastPrice,
  changeRate24H
}) => {
  const t = useFm();
  return (
    <section className={styles.rankRow}>
      <span className={cls(styles.leftWidth)}>
        {symbolAlias && (
          <img className={styles.itemIcon} src={getSymbolUrl(symbolAlias)} />
        )}
        <span> {symbolAlias} </span>
      </span>
      <span className={cls(styles.center, styles.centerWidth)}>
        {formattedLastPrice}
      </span>

      <span
        className={cls(styles.itemsCenter, {
          [styles.greenColor]: changeRate24H >= 0,
          [styles.redColor]: changeRate24H < 0
        })}
      >
        <span
          className={cls(styles.icon, {
            [styles.greenIcon]: changeRate24H >= 0,
            [styles.redIcon]: changeRate24H < 0
          })}
        />
        {changeRate24H >= 0 ? `+${changeRate24H}` : changeRate24H}%
      </span>
    </section>
  );
};

export default RowData;
